<template lang="pug">
  v-btn(
    :shop="shop"
    :order="order"
    :color="!text ? 'white' : 'primary'"
    :elevation="!text ? 0 : 2"
    @click.stop.prevent="downloadCSV"
  ) {{ text }}
    v-icon(:right="text" :left="!text") mdi-export
</template>

<script>
import axios from 'axios'

export default {
  name: 'DownloadOrderButton',
  props: {
    order: {
      type: Object,
      required: true
    },
    shop: {
      type: Object,
      required: false
    },
    text: {
      type: String,
      required: false
    }
  },
  computed: {
    user () {
      return this.$store.getters['auth/user']
    }
  },
  methods: {
    async downloadCSV () {
      const token = localStorage.getItem('feathers-jwt')
      const instance = axios.create({
        baseURL: process.env.VUE_APP_API_URL,
        headers: {
          authorization: token
        }
      })

      const response = await instance.get('/orders-csv/' + this.order.id)

      var blob = new Blob(['\ufeff', response.data])
      var url = URL.createObjectURL(blob)
      const downloadLink = document.createElement('a')
      downloadLink.href = url
      if (!this.shop) {
        downloadLink.download =
          this.$day(this.order.createdAt).format('MM/DD/YY') +
          '-' +
          this.order.shop.name +
          '.csv'
      } else {
        downloadLink.download =
          this.$day(this.order.createdAt).format('MM/DD/YY') +
          '-' +
          this.shop.name +
          '.csv'
      }

      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)

      try {
        this.user.isAdmin
          ? await this.order.patch({ data: { status: 'PROCESSED' } }) && this.$snackSuccess('Order marked as Processed') : this.$snackSuccess('Order Downloaded')
      } catch (error) {
        this.$snackError(error.message)
      }
    }
  }
}
</script>
